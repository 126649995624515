@import './variables';
@import '../fonts/fonts.css';
@import '../../components/DateSelect/DateSelect.scss';

:root {
  --layout-top-padding: 66px;
  --layout-sidebar-width: 220px;

  @media (min-width: 768px) {
    --layout-top-padding: 10px;
  }

  @media (min-width: 1024px) {
    --layout-sidebar-width: 240px;
  }

  @media (min-width: 1760px) {
    --layout-sidebar-width: 260px;
  }
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  color: $text-dark;
  font-weight: 500;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-drag: none;

  touch-action: none !important;
  -ms-touch-action: none !important;
  overflow: hidden !important; // Note: always handle inner scrolling, disable drag
}

:root:has(
    :is(
        .modal-open,
        .modal:target,
        .modal-toggle:checked + .modal,
        .modal[open]
      )
  ) {
  scrollbar-gutter: auto;
}
#root,
#app-layout {
  height: 100%;
}

.logout-icon {
  fill: #ff4d54;

  &:hover {
    fill: #8a0005;
  }
}
.marker {
  background-size: cover;
  width: 20.5px;
  height: 20.5px;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 2.5px;
  border-radius: 5px;
}
.customer-package {
  .marker {
    width: 14px;
    height: 14px;
    margin-top: 6px;
  }
}

// .parent-marker:has(.active-order) {
//   width: 40px;
//   height: 40px;
// }

.active-order {
  width: 70px;
  height: 67px;
  background: url('../img/pin.svg') no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  width: 240px;
  &:after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    // background: url(../../assets/img/arrowDown.svg) no-repeat;
    // background-size: contain;
    // top: 98%;
    // left: 50%;
    transform: translateX(-50%);
  }
}

.active-order > div {
  /* Styles for the direct child div */
  width: 40px;
  height: 46px;
  // padding: 15px;
}

.customer-image {
  width: 25px;
  height: 33px;
  background: url('../img/pin-light.svg') no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  &.intransit-image {
    background-color: $primary;
    border-radius: 50%;
    border: 1px solid #fff;
    background-image: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: '';
      height: 200%;
      width: 200%;
      position: absolute;
      border: 2px solid #079455;
      border-radius: 50%;
      opacity: 0.5;
    }
    .marker {
      width: 20px;
      height: 21px;
      margin-top: -2px;
    }
  }
}
.parcel-arrow {
  background-color: #6172f3;
  border-radius: 50%;
  border: 3px solid #fff;
  background-image: none;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3.571px 3.571px 0px rgba(0, 0, 0, 0.1),
    0px 3.571px 3.571px 0px rgba(0, 0, 0, 0.1),
    0px 3.571px 7.143px -1.786px rgba(16, 24, 40, 0.1);
}
.hover-parcel-arrow{
  transition: 0.2s ease all;
  transform: scale(1);
  &:hover{
    transform: scale(1.1);
    background-color: #444CE7;
  }
}
.radar-mapbox {
  .mapboxgl-popup-content {
    padding: 0;
    background: transparent;
    border-radius: 6px;
  }
  .mapboxgl-popup-tip {
    display: none;
  }

  .mapbox-tooltip-dark {
    border-radius: 6px;
    background-color: #0c111d;
    padding: 4px 8px;
    color: #fff;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
  }
}
.d-none {
  display: none;
}
.customer-card-box {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  padding: 12px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  bottom: 9px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 240px;
  &:after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background: url(../../assets/img/arrowDown.svg) no-repeat;
    background-size: contain;
    top: 98%;
    left: 50%;
    transform: translateX(-50%);
  }
  .customer-title {
    color: #101828;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
  }
  .customer-address {
    color: #667085;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
  }
}

.wal__sidebar_link,
.wal__sidebar_sub_link {
  transition: all 0.3s ease;

  &:hover {
    .link_icon {
      color: #101828;
    }
  }
}

.wal__sidebar_sub_line_container {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 3px;
    width: 2px;
    height: 30px;
    background-color: #98a2b3;
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 3px;
    width: 2px;
    height: 30px;
    background-color: #98a2b3;
    z-index: -1;
  }

  &.is_first_child {
    &::before {
      content: none;
    }
  }

  &.is_last_child {
    &::after {
      content: none;
    }
  }
}

.main__layout_section {
  height: inherit;
  overflow: auto;
}

$background-color_5: rgba(22, 66, 197, 0.1);
$background-color_4: rgba(22, 66, 197, 0.1);

.wal-hq-select {
  .select__placeholder {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .hide-select-value-container {
    .select__control {
      display: none;
    }

    .date-picker-menu {
      box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
        0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    }

    .select__menu {
      position: relative;
      box-shadow: none;
      background-color: transparent;
      margin: 0;
      z-index: auto;
      border-radius: 0;
    }

    .select__menu-list {
      max-height: 416px;
      min-width: 189px;
      padding: 12px 8px;
      margin: 0;

      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #eaecf0;
      }

      @media (min-width: 1024px) {
        min-width: 136px;
      }

      @media (min-width: 1360px) {
        min-width: 156px;
      }

      @media (min-width: 1760px) {
        min-width: 189px;
      }
    }
  }

  .select__menu-list {
    overflow-x: auto;
    overflow-y: auto;
    padding: 0;
    max-height: 145px;
    margin: 4px 6px;
    @media (min-width: 1024px) {
      max-height: 180px;
    }
    &::-webkit-scrollbar {
      height: 0;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #eaecf0;
    }
  }

  .select__control {
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    overflow-x: auto;
    min-height: 34px;
    height: fit-content;
    border-color: #d0d5dd;
    box-shadow: none;
    @media (min-width: 1024px) {
      min-height: 36px;
    }

    &:focus {
      border-color: $text-light;
      box-shadow: none;
    }

    &:focus-within {
      border-color: $text-light;
      box-shadow: none;
    }

    &:active {
      border-color: $text-light;
      box-shadow: none;
    }

    &:hover {
      border-color: $text-light;
      box-shadow: none;
    }

    .select__value-container {
      color: #667085;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      display: flex;
      flex-wrap: nowrap !important;
      overflow-x: auto;
      padding: 4px 0 4px 10px;
      @media (max-width: 1024px) {
        padding: 3px 0 3px 10px;
      }
      &::-webkit-scrollbar {
        height: 2px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: $primary;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $primary;
      }

      .select__input-container {
        .select__input {
          outline: none;
          box-shadow: unset;
        }
      }

      .css-olqui2-singleValue {
        color: #667085;
      }
    }

    .select__indicators {
      // width: 32px;
      // padding-right: 12px;
      svg {
        color: #667085;
        width: 16px;
      }

      .select__indicator-separator {
        display: none;
      }
      .select__clear-indicator {
        background: url('../img/cross-select-indicator.svg') no-repeat center;
        background-size: 14px;
        margin: 0 8px 0 4px;
        padding: 5px;
      }
      .select__clear-indicator {
        svg {
          display: none;
        }
        // &:after{
        //   cursor: pointer;
        //   background-color: #216ba5;
        //   color: #fff;
        //   border-radius: 50%;
        //   height: 16px;
        //   width: 16px;
        //   padding: 0;
        //   font-size: 12px;
        //   line-height: 1;
        //   text-align: center;
        //   display: table-cell;
        //   vertical-align: middle;
        //   content: "×";
        //   font-size: 18px;
        //   background-color: transparent;
        //   color: #667085;
        //   margin-top: -2px;
        // }
      }
    }

    .select__placeholder {
      color: #667085;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .select__multi-value {
      background-color: #f2f4f7 !important;
      display: flex;
      border-radius: 6px !important;
      min-width: fit-content !important;
      // border: 1px solid #d0d5dd !important;
      margin: 0 4px;
      align-items: center;
      .select__multi-value__remove {
        padding: 0 5px 0 0;
      }
    }

    .select__multi-value__label {
      font-size: 12px !important;
      padding: 2px 5px;
      color: #344054;
      font-weight: 500;
    }
    .select__value-container--is-multi.select__value-container--has-value {
      padding-left: 2px !important;
    }
    .select__multi-value__remove {
      &:hover {
        color: $text-danger;
        background-color: transparent;
      }
    }
  }

  .select__menu {
    border-radius: 8px;
    margin-top: 10px;
    overflow: hidden;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    cursor: pointer;
    z-index: 30;
    border: 1px solid #eaecf0;
  }

  .select__option {
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 12px;
    color: #101828;
    line-height: 18px;
    background-color: #fff;
    padding: 8px;
    border-radius: 0;

    @media (min-width: 1360px) {
      padding: 8px;
    }

    &:not(:last-child) {
      // margin-bottom: 2px;
    }
    .sub-label {
      color: #475467;
    }
    &:hover,
    &.select__option--is-selected,
    &.select__option--is-focused {
      background-color: #f9fafb;
      border-radius: 6px;
      color: #444ce7;
      .sub-label {
        color: #444ce7;
      }
    }

    // &.select__option--is-selected {
    //     background-color: $background-color_5;
    //     color: #000;
    //     margin: 5px 0px;
    // }
  }

  .select__option--is-focused {
    // background-color: #fff;
    // color: #000;
  }

  .select__control--is-disabled {
    background-color: #f9fafb;

    .select__indicators {
      svg {
        color: #d0d5dd;
      }
    }
  }

  .select__indicator {
    color: #000;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0;
  }
}

.form_control.is-invalid {
  border: 1px solid #f04438;
}

.wal-hq-select.is-invalid {
  border: 0;

  .select__control {
    border: 1px solid #f04438;
  }

  .select__control--is-focused {
    outline: none;
    box-shadow: unset;
  }
}

// .wal-hq-select {
//     .wal-hq__control {
//         flex: 1;
//         justify-content: center;
//         padding: 0;
//     }

// }

.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__close-icon {
    right: 25px;
    color: #667085;
    background: url('../img/cross-select-indicator.svg') no-repeat center;
    background-size: 14px;
    margin: 0 8px 0 4px;
    padding: 5px;
    &::after {
      font-size: 18px;
      background-color: transparent;
      color: #667085;
      display: none;
    }
  }
  .react-datepicker__calendar-icon {
    width: 16px;
    right: 0;
  }
}

// .react-datepicker-popper{}
.react-datepicker {
  border: none;
  border-radius: 8px;
  background: #fff;
  display: flex;
  box-shadow: 0px 5.3333330154418945px 15.999999046325684px 0px
    rgba(146, 140, 151, 0.25);

  .react-datepicker__triangle {
    &::after {
      content: none !important;
    }

    &::before {
      content: none !important;
    }
  }

  .react-datepicker__header {
    background-color: transparent;

    &:not(.react-datepicker__header--has-time-select) {
      border-bottom: none;
    }

    .react-datepicker__current {
      &-month {
        margin: 1rem 0;
      }
    }
  }

  .react-datepicker__navigation {
    top: 18px;
    border: 1px solid #f1f1f1;
    height: 38px;
    width: 38px;
    margin: 0 1rem;
    border-radius: 8px;

    .react-datepicker__navigation-icon {
      &--next {
        left: 0;
        top: 0;
      }

      &--previous {
        left: 26px;

        &::before {
          right: 20px;
        }
      }

      &::before {
        border-width: 2px 2px 0px 0;
        border-color: $text-light;
        border-radius: 1px;
      }
    }
  }

  .react-datepicker__day-names {
    .react-datepicker__day-name {
      color: $text-light;
      font-size: 12px;
      width: 2.75rem;
      margin: 0;
      padding: 0.5rem 0.75rem;
    }
  }

  .react-datepicker__day {
    font-size: 12px;
    width: 2.75rem;
    margin: 0;
    padding: 0.25rem 0.75rem;

    &--keyboard-selected {
      background-color: $primary;
      color: #fff;
    }

    &--selected {
      background-color: #e0eaff;
      outline: 0;
      // color: #fff;
    }
  }

  .react-datepicker__month {
    margin: 1rem;
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: #344054;
  }
}
.react-datepicker__view-calendar-icon input {
  padding-left: 10px;
  cursor: pointer;
  touch-action: none;
  &:focus {
    cursor: default;

    caret-color: transparent; /* Hide the caret (text cursor) */
  }
}

.datepicker-modal {
  .custom-time-range {
    & > div:nth-child(1) {
      overflow-y: auto;
      overflow-x: hidden;
      margin-right: 5px;
      width: calc(100% - 5px);

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #eaecf0;
      }

      .react-datepicker {
        min-height: 299px;

        @media (min-width: 1360px) {
          min-height: 334px;
        }
      }
    }
  }
}
.fixed-width-datepicker {
  .wal-single-date-picker {
    .react-datepicker-popper {
      min-width: 284px;
      .react-datepicker__month-container {
        border: 0 !important;
      }
    }
  }
}
.one-month-datepicker {
  .react-datepicker__month-container {
    border: 0 !important;
  }
  .react-datepicker-popper {
    justify-content: center;
  }
  .react-datepicker {
    justify-content: center;
  }
}
.date-time-picker {
  .wal-hq-select {
    .hide-select-value-container {
      .select__menu-list {
        min-width: 150px;
      }
    }
  }
}

.datepicker-w-auto {
  .react-datepicker-popper {
    @media (min-width: 991px) {
      min-width: fit-content !important;
    }
  }
}
.right-side-datepicker {
  .wal-single-date-picker {
    .react-datepicker-popper {
      left: unset !important;
      right: 0 !important;
    }
  }
}
.wal-single-date-picker {
  .react-datepicker-popper {
    background-color: white;
    position: absolute;
    // left: 0 !important;
    // right: unset !important;
    border: 1px solid #eaecf0;
    border-radius: 12px;
    margin-top: 4px;
    display: flex;
    z-index: 9999;
    @media (min-width: 991px) {
      // min-width: 100%;
    }
  }

  .react-datepicker__day--today {
    position: relative;
    &:before {
      content: '';
      height: 5px;
      width: 5px;
      background-color: $primary;
      border-radius: 50%;
      position: absolute;
      top: 77%;
      left: 50%;
      transform: translateX(-50%);
      @media (max-width: 991px) {
        height: 4px;
        width: 4px;
      }
    }
    &.react-datepicker__day--selected {
      &:before {
        // background-color: #fff;
      }
    }
  }
  .react-datepicker {
    border-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: none;
    background-color: transparent;
  }

  .react-datepicker {
    border-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: none;
    background-color: transparent;
  }

  .react-datepicker__day--in-selecting-range {
    background-color: #f2f4f7;

    &.react-datepicker__day--selected {
      background-color: $primary;
    }
  }

  .react-datepicker__header {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    // .react-datepicker__day-names {
    //     flex: 0 0 100%;
    // }

    .react-datepicker__current-month {
      margin: 16px 0 12px;
      color: #344054;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
    }

    .react-datepicker__navigation-icon {
      &::before {
        border-color: #344054;
        top: 13px;
      }
    }
  }

  .react-datepicker__month-container {
    &:last-child {
      @media (min-width: 1024px) {
        border-left: 1px solid #eaecf0;
      }
    }
  }

  .react-datepicker__navigation {
    border: 0;
    height: 30px;
    width: 30px;
    margin: 0 5px;
    top: 10px;

    @media (min-width: 1024px) {
      width: 30px;
      margin: 0 15px;
    }

    @media (min-width: 1360px) {
      width: 35px;
      margin: 0 18px;
    }

    .react-datepicker__navigation-icon {
      &::before {
        border-color: #344054;
        top: 13px;
      }
    }
  }

  .react-datepicker__month {
    margin: 0 5px 0px;
    padding-bottom: 0;
    @media (min-width: 640px) {
      margin: 0 5px 16px;
    }
    @media (min-width: 1360px) {
      margin: 0 24px 16px;
    }
    @media (min-width: 768px) {
      margin: 0 15px 16px;
    }
  }

  .react-datepicker__header {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .react-datepicker__day-names {
      flex: 0 0 100%;
    }

    .react-datepicker__current-month {
      margin: 16px 0 12px;
      color: #344054;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
    }

    .react-datepicker__header__dropdown--scroll {
      .react-datepicker__year-dropdown-container {
        font-family: Inter;
        margin: 10px 10px 12px;

        .react-datepicker__year-option {
          &:not(:first-child, :last-child) {
            color: #475467;
            padding: 5px 0;
            margin-bottom: 4px;
            font-size: 12px;
            border-radius: 6px;
            transition: 0.5s ease all;

            &:hover,
            &.react-datepicker__year-option--selected_year {
              background-color: #eef4ff;
              color: $primary;
            }

            .react-datepicker__year-option--selected {
              color: $primary;
            }
          }

          &:hover {
            background-color: transparent;
          }
        }

        .react-datepicker__year-dropdown {
          border-radius: 8px;
          border: 1px solid #eaecf0;
          background: #fff;
          padding: 0 8px;
          box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
            0px 12px 16px -4px rgba(16, 24, 40, 0.08);
          top: 35px;
          z-index: 3;

          .react-datepicker__navigation--years {
            border-width: 2px 2px 0px 0;
            border-color: #98a7c3;
            border-radius: 1px;
            border-color: #344054;
            height: 9px;
            width: 9px;
            border-style: solid;
            margin: 15px auto 10px;
            transform: rotate(-45deg);

            &:hover {
              background-color: transparent;
            }

            &.react-datepicker__navigation--years-previous {
              transform: rotate(135deg);
              margin: 5px auto 19px;
            }
          }
        }
      }

      .react-datepicker__year-read-view {
        visibility: visible !important;
      }

      .react-datepicker__year-read-view--selected-year {
        font-size: 14px;
        font-weight: 600;
        color: #344054;
      }

      .react-datepicker__year-read-view--down-arrow {
        top: 4px;
        border-color: #344054;
        border-width: 2px 2px 0px 0;
        border-radius: 1px;
      }
    }
  }

  .react-datepicker__week {
    margin-bottom: 4px;

    .react-datepicker__day--in-range {
      &:last-child {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }

      &:first-child {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }
  }

  .react-datepicker__day-names {
    margin: 0 0 4px;

    .react-datepicker__day-name {
      font-family: Inter;
      font-size: 12px;
      color: #344054;
      padding: 0px 5px;
      width: 26px;
      line-height: 26px;
      margin: 0 3px;
      @media (min-width: 640px) {
        padding: 1.42px 5px;
        width: 30px;
        line-height: 1.7rem;
        margin: 0;
      }
      @media (min-width: 1024px) {
        padding: 1.42px 5px;
        width: 30px;
      }

      @media (min-width: 1360px) {
        padding: 3.92px 8px;
        width: 35px;
      }
    }
  }

  .react-datepicker__day {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    color: #344054;
    padding: 0px 5px;
    width: 26px;
    line-height: 26px;
    border-radius: 50%;
    margin: 0 3px;
    @media (min-width: 640px) {
      padding: 1.42px 5px;
      width: 30px;
      line-height: 1.7rem;
      margin: 0;
    }
    @media (min-width: 1024px) {
      padding: 1.42px 5px;
      width: 30px;
    }

    @media (min-width: 1360px) {
      padding: 3.92px 8px;
      width: 35px;
    }

    &.react-datepicker__day--outside-month {
      color: #667085;

      &:empty {
        background-color: transparent;

        &:after {
          background-color: transparent;
        }
      }
    }

    &.react-datepicker__day--selected {
      // color: #fff;
    }
  }

  .react-datepicker__day--keyboard-selected {
    color: #fff;
  }

  .react-datepicker__day--in-range {
    background-color: #f2f4f7;
    border-radius: 0;
    color: #344054;

    &.react-datepicker__day--keyboard-selected {
      background-color: #f2f4f7;
    }

    &.react-datepicker__day--selected,
    &.react-datepicker__day--range-end {
      border-top-left-radius: 9999px;
      border-bottom-left-radius: 9999px;
      position: relative;
      z-index: 2;
      color: #fff;

      &:after {
        content: '';
        position: absolute;
        background-color: #444ce7;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        border-radius: 50%;
      }
    }

    &.react-datepicker__day--range-end {
      &.react-datepicker__day--selected {
        border-radius: 50%;
      }
    }

    &.react-datepicker__day--range-end {
      border-radius: 0;
      border-top-right-radius: 9999px;
      border-bottom-right-radius: 9999px;
    }
  }
}
.react-datepicker-popper[data-placement^='top'] {
  padding-bottom: 5px;
}
.react-datepicker__day--disabled {
  color: #667085 !important;
  opacity: 0.6;
}
.custom-time-range {
  min-width: 245px;

  .react-datepicker {
    border-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: none;
    background-color: transparent;
  }

  // margin-left: 193px;
  @media (min-width: 1024px) {
    margin-left: 140px;
  }

  @media (min-width: 1360px) {
    margin-left: 160px;
  }

  @media (min-width: 1760px) {
    margin-left: 193px;
  }

  .react-datepicker {
    border-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: none;
    background-color: transparent;
    .react-datepicker__day--today {
      position: relative;
      &:empty {
        &:before {
          display: none;
        }
      }
      &:before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: $primary;
        border-radius: 50%;
        position: absolute;
        top: 77%;
        left: 50%;
        transform: translateX(-50%);
      }
      &.react-datepicker__day--selected {
        &:before {
          // background-color: #fff;
        }
      }
    }
  }

  .react-datepicker__day--in-selecting-range {
    background-color: #f2f4f7;

    &.react-datepicker__day--selected {
      background-color: #e0eaff;
    }
  }

  .react-datepicker__header {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    // .react-datepicker__day-names {
    //     flex: 0 0 100%;
    // }

    .react-datepicker__current-month {
      margin: 16px 0 12px;
      color: #344054;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
    }

    .react-datepicker__navigation-icon {
      &::before {
        border-color: #344054;
        top: 13px;
      }
    }
  }

  .react-datepicker__month-container {
    &:last-child {
      @media (min-width: 1024px) {
        border-left: 1px solid #eaecf0;
      }
    }
  }

  .react-datepicker__navigation {
    border: 0;
    height: 30px;
    width: 30px;
    margin: 0 15px;
    top: 10px;

    @media (min-width: 1024px) {
      width: 30px;
      margin: 0 15px;
    }

    @media (min-width: 1360px) {
      width: 35px;
      margin: 0 18px;
    }

    .react-datepicker__navigation-icon {
      &::before {
        border-color: #344054;
        top: 13px;
      }
    }
  }

  .react-datepicker__month {
    margin: 0 15px 16px;
    padding-bottom: 20px;

    @media (min-width: 1360px) {
      margin: 0 24px 16px;
    }
  }

  .react-datepicker__header {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .react-datepicker__day-names {
      flex: 0 0 100%;
    }

    .react-datepicker__current-month {
      margin: 16px 0 12px;
      color: #344054;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
    }

    .react-datepicker__header__dropdown--scroll {
      .react-datepicker__year-dropdown-container {
        font-family: Inter;
        margin: 10px 10px 12px;

        .react-datepicker__year-option {
          &:not(:first-child, :last-child) {
            color: #475467;
            padding: 5px 0;
            margin-bottom: 4px;
            font-size: 12px;
            border-radius: 6px;
            transition: 0.5s ease all;

            &:hover,
            &.react-datepicker__year-option--selected_year {
              background-color: #eef4ff;
              color: $primary;
            }

            .react-datepicker__year-option--selected {
              color: $primary;
            }
          }

          &:hover {
            background-color: transparent;
          }
        }

        .react-datepicker__year-dropdown {
          border-radius: 8px;
          border: 1px solid #eaecf0;
          background: #fff;
          padding: 0 8px;
          box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
            0px 12px 16px -4px rgba(16, 24, 40, 0.08);
          top: 35px;
          z-index: 3;

          .react-datepicker__navigation--years {
            border-width: 2px 2px 0px 0;
            border-color: #98a7c3;
            border-radius: 1px;
            border-color: #344054;
            height: 9px;
            width: 9px;
            border-style: solid;
            margin: 15px auto 10px;
            transform: rotate(-45deg);

            &:hover {
              background-color: transparent;
            }

            &.react-datepicker__navigation--years-previous {
              transform: rotate(135deg);
              margin: 5px auto 19px;
            }
          }
        }
      }

      .react-datepicker__year-read-view {
        visibility: visible !important;
      }

      .react-datepicker__year-read-view--selected-year {
        font-size: 14px;
        font-weight: 600;
        color: #344054;
      }

      .react-datepicker__year-read-view--down-arrow {
        top: 4px;
        border-color: #344054;
        border-width: 2px 2px 0px 0;
        border-radius: 1px;
      }
    }
  }

  .react-datepicker__week {
    margin-bottom: 4px;

    .react-datepicker__day--in-range {
      &:last-child {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }

      &:first-child {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }
  }

  .react-datepicker__day-names {
    margin: 0 0 4px;

    .react-datepicker__day-name {
      font-family: Inter;
      font-size: 12px;
      color: #344054;
      padding: 1.42px 5px;
      width: 30px;

      @media (min-width: 1024px) {
        padding: 1.42px 5px;
        width: 30px;
      }

      @media (min-width: 1360px) {
        padding: 3.92px 8px;
        width: 35px;
      }
    }
  }

  .react-datepicker__day {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    color: #344054;
    padding: 1.42px 5px;
    width: 30px;
    border-radius: 50%;

    @media (min-width: 1024px) {
      padding: 1.42px 5px;
      width: 30px;
    }

    @media (min-width: 1360px) {
      padding: 3.92px 8px;
      width: 35px;
    }

    &.react-datepicker__day--outside-month {
      color: #667085;

      &:empty {
        background-color: transparent;

        &:after {
          background-color: transparent;
        }
      }
    }

    &.react-datepicker__day--selected {
      // color: #fff;
    }
  }

  .react-datepicker__day--keyboard-selected {
    color: #fff;
  }

  .react-datepicker__day--in-range {
    background-color: #f2f4f7;
    border-radius: 0;
    color: #344054;

    &.react-datepicker__day--keyboard-selected {
      background-color: #f2f4f7;
    }

    &.react-datepicker__day--selected,
    &.react-datepicker__day--range-end {
      border-top-left-radius: 9999px;
      border-bottom-left-radius: 9999px;
      position: relative;
      z-index: 2;
      // color: #fff;

      &:after {
        content: '';
        position: absolute;
        background-color: #e0eaff;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        border-radius: 50%;
      }
    }

    &.react-datepicker__day--range-end {
      &.react-datepicker__day--selected {
        border-radius: 50%;
      }
    }

    &.react-datepicker__day--range-end {
      border-radius: 0;
      border-top-right-radius: 9999px;
      border-bottom-right-radius: 9999px;
    }
  }
}

.date-picker-menu {
  > div:nth-child(1) {
    position: absolute;
    height: 100%;

    .wal-hq-select,
    .hide-select-value-container,
    .select__menu {
      height: inherit;
      top: 0;
    }

    .select__menu-list {
      height: 100%;
    }

    .select__menu {
      border: 0;
    }
  }

  .wal-hq-select {
    .select__option {
      border-radius: 6px;

      &:hover,
      &.select__option--is-selected {
        background-color: #f9fafb;
      }
    }
  }

  .custom-time-range {
    .react-datepicker__month {
      padding: 0;
    }

    // .react-datepicker__month-container {
    //     margin-bottom: 40px;
    // }
  }
}

.switcher {
  &.switch-custom {
    .switcher__toggle {
      left: 0;
      transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
      transform: translateX(4%);
    }
    .switcher__input--yang {
      &:checked {
        ~ .switcher__toggle {
          left: 0;
          transform: translateX(96%);
        }
      }
    }
  }
}
.switcher {
  display: inline-block;
  padding: 6px;
  border-radius: 10px;
  width: 100%;
  // border: solid 1px #1F242F;
  // background-color: #1F242F;
  position: relative;

  .switcher__input {
    display: none;
    color: $text-white;

    &--yang:checked ~ .switcher__toggle {
      left: 95px;
      color: $text-white;
    }
  }

  .switcher__label {
    float: left;
    width: 50%;
    font-size: 10px;
    line-height: 24px;
    // color: $text-white;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    position: inherit;
    z-index: 1;
    transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;

    .w-fit {
      width: 100%;
    }
  }

  .switcher__toggle {
    position: absolute;
    float: left;
    width: 50%;
    font-size: 14px;
    cursor: pointer;
    background-color: #e0eaff;
    // color:#4caf50;
    border-radius: 6px;
    left: 4px;
    padding: 14px;
    top: 4px;
    align-items: center;
    transition: left 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
  }
}

.switch-sm {
  width: 233px !important;
  // height:30px;
  // margin-bottom: 1rem;

  .switcher__input--yang:checked ~ .switcher__toggle {
    left: 111px;
  }
}

.switch-md {
  width: 256px !important;
  margin-bottom: 1rem;

  .switcher__input--yang:checked ~ .switcher__toggle {
    left: 124px;
    color: $text-white;
  }

  .switcher__toggle {
    position: absolute;
    float: left;
    width: 50%;
    font-size: 14px;
    cursor: pointer;
    background-color: #e0eaff;
    border-radius: 6px;
    left: 4px;
    padding: 18px;
    top: 1px;
    transition: left 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
  }
}

.dimention_card_gray {
  select,
  input {
    padding: 0;
    border: 0;

    &:focus {
      outline: none;
      border-color: transparent;
    }
  }
}

.wal_accordion {
  button {
    > svg {
      color: $primary;
      rotate: unset;
      transform: unset;
    }
  }
}

.drawer_body {
  @media (min-width: 1440px) {
    height: calc(100vh - 90px);
  }
}

.dimension_input {
  margin-bottom: 1rem;
  width: 165px;

  @media (min-width: 1740px) {
    width: 158px;
  }
}

.block_ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.border-t-0 {
  border-top-width: 0 !important;
}

.mapit-address-list > .pac-item > .pac-icon {
  display: none;
  margin-left: 10px;
}

.pac-container {
  border-radius: 8px;
  background-color: #fff;
  border: none;
  margin-top: 8px;
  box-shadow: 0px 5.33px 15.99px 0px rgba(146, 140, 151, 0.25);
  position: absolute !important;
  overflow-y: auto;
  z-index: 99999;
  // / Define a CSS variable for top position /
  --container-top: 620px;

  // / Media queries for different screen sizes /
  @media (max-width: 768px) {
    --container-top: 520px;
  }

  @media (max-width: 480px) {
    --container-top: 420px;
  }

  // / Apply the dynamic top value /
  // top: var(--container-top);

  // / ... rest of your styles ... /
}

.pac-item {
  padding: 6px 12px;
  border: none;
  color: #9ca3af;
  font-size: 14px;

  &:hover {
    background-color: #f1f4f8;
  }
}
.pac-item-query {
  color: #101828;
}
.pac-icon {
  &.pac-icon-marker {
    display: none;
  }
}
.pac-container::after {
  content: unset;
}

// toggle switch type money or percent
.toggle_button {
  display: inline-block;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  transition: background 600ms ease, color 600ms ease;
}

input[type='radio'].toggle_icons {
  display: none;

  & + label {
    cursor: pointer;
    color: #98a7c3;

    &:after {
      border-radius: 32px;
      background: #fff;
      box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.05);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
      width: 100%;
      z-index: -1;
    }
  }

  &.toggle-left + label {
    border-right: 0;

    &:after {
      left: 100%;
    }
  }

  &.toggle-right + label {
    margin-left: -2px;

    &:after {
      left: -93%;
    }
  }

  &:checked + label {
    cursor: default;
    color: #1f293c;
    transition: color 200ms;

    &:after {
      left: 0;
    }
  }
}

// loader

.loader_spinner {
  border-top-color: $primary;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  min-height: 34.3rem;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.top_right_radius {
  border-top-right-radius: 6px;
}

.card_shadow {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.footer_shadow {
  box-shadow: 0px -1px 10px rgba(160, 160, 160, 0.1);
}

li[aria-current='page'] {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity));
  color: rgb(28 100 242 / var(--tw-text-opacity));
}

.service-price {
  th:last-of-type {
    text-align: right;
  }
}

// success animation
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.spot-quote-th-bg {
  background-color: #3d738d;
}

.lang__control {
  padding: 5px !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: rgb(16 24 40 / var(--tw-text-opacity)) !important;
}

.gradient-text {
  background: linear-gradient(to right, #4e4ce7 0%, #9848e7 74%, #b944e7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Change the white to any color */
.dark-input:-webkit-autofill,
.dark-input:-webkit-autofill:hover,
.dark-input:-webkit-autofill:focus,
.dark-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #0c111d inset !important;
}

.dark-input:-webkit-autofill {
  -webkit-text-fill-color: #667085 !important;
}

.Toastify__toast {
  border-radius: 12px !important;
}

// for zoom out
.btn-xs-custom {
  height: 35px;
  min-height: 35px;
}

.btn-heigh-auto {
  height: auto;
  min-height: auto;
}

.wal_radio:checked {
  border: none;
}

.wal_radio:checked + .check-icon {
  display: flex;
}

/* For Webkit browsers like Chrome and Safari */
.hide-btn[type='number']::-webkit-inner-spin-button,
.hide-btn[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.hide-btn[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* For IE */
.hide-btn[type='number']::-ms-clear,
.hide-btn[type='number']::-ms-reveal {
  display: none;
}

#chart-container {
  width: 100%;
  /* Fixed width */
  overflow-x: auto;
  /* Enables horizontal scrolling */
  overflow-y: hidden;
  /* Prevents vertical scrolling */
}

#sales-chart-container {
  // max-width: 1700px; /* Fixed width */
  width: 100%;
}

.overflow-unset {
  overflow: unset;
}

.apexcharts-canvas {
  text {
    font-family: Inter !important;
  }

  .apexcharts-legend-marker {
    border-radius: 50% !important;
    height: 7px !important;
    width: 7px !important;
    margin-right: 7px !important;
  }

  .apexcharts-legend-text {
    color: #615e83 !important;
  }

  .apexcharts-yaxis-title {
    text {
      font-weight: 500;
    }
  }

  .apexcharts-graphical {
    .apexcharts-active {
      opacity: 0;
    }
  }
}

.react-datepicker {
  &.pos-absolute {
    position: absolute !important;
    z-index: 99;
  }
}
.radial-progress {
  overflow: hidden;
  .target-div {
    position: absolute;
    inset: 0px;
    background: radial-gradient(farthest-side, currentColor 98%, #0000) top /
        var(--thickness) var(--thickness) no-repeat,
      conic-gradient(currentColor calc(var(--value) * 1%), #00000000 0);
    mask: radial-gradient(
      farthest-side,
      #0000 calc(99% - var(--thickness)),
      #000 calc(100% - var(--thickness))
    );

    opacity: 0.3;
  }
}
.radial-progress:before {
  background: radial-gradient(farthest-side, currentColor 98%, #0000) top / 3px
      3px no-repeat,
    conic-gradient(currentColor calc(var(--value) * 1%), #eaecf0 0);
}

// .radial-progress:after{
//     background-color: green;
// }
.custom-loading-v2 {
  position: relative;
  visibility: hidden;
  pointer-events: none;
  &:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: custom-loading-v2 2.15s linear 0s infinite;
    background-position: 0 0;
    background-size: 200% 100% !important;
    background: linear-gradient(
      33deg,
      rgba(240, 244, 249, 20%) 0,
      rgba(233, 238, 246, 100) 25%,
      rgba(72, 127, 237, 100) 50%,
      rgba(233, 238, 246, 100) 75%,
      rgba(240, 244, 249, 0.2) 100%
    );
    border-radius: inherit;
    visibility: visible;
    border-radius: 4px;
  }
  &.custom-loading-v2-delay {
    &:after {
      animation-delay: 0.5s;
    }
  }
  &.custom-loading-v2-moredelay {
    &:after {
      animation-delay: 1s;
    }
  }
}
@keyframes custom-loading-v2 {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -200% 0;
  }
}
.custom-loading {
  position: relative;
  // margin-bottom: 1px;
  // border: 0;
  visibility: hidden;
  pointer-events: none;
  &:after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    background-color: #eaecf0;
    border-radius: inherit;
    visibility: visible;
  }

  &.loading-dark {
    &:after {
      background-color: #d1d5db;
    }
  }

  &.image-loading {
    &:after {
      background-image: url('../../assets/img/image-loading.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 25% 25%;
    }
  }

  &.user-loading {
    &:after {
      background-image: url('../../assets/img/user-loading.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }
  }

  &.graph-loading {
    visibility: visible;

    text {
      visibility: hidden;
    }
    &.apex-line-chart {
      .apexcharts-canvas {
        svg {
          path {
            stroke: #eaecf0;
            fill: revert-layer;
          }
        }
      }
      .apexcharts-inner {
        linearGradient {
          stop {
            stop-color: #eaecf0;
          }
        }
      }
    }

    svg {
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

      path {
        fill: #eaecf0;
        visibility: visible;
        filter: none;
      }
    }

    &:after {
      background-color: transparent;
    }
  }
}

.custom-loading-heat-map {
  svg {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  rect {
    fill: #eaecf0;
    visibility: visible;
    filter: none;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #eaecf0;
  }
}

.custom-scrollbar-v2,
.novel-editor-options {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #eaecf0;
  }
}
.custom-scrollbar-v3 {
  &::-webkit-scrollbar {
    width: 10px;
    border: 4px solid #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #eaecf0;
    border: 3px solid #fff;
  }
}
.custom-scrollbar-v3-dark {
  &::-webkit-scrollbar {
    width: 10px;
    border: 4px solid #000;
    border-radius: 24px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #eaecf0;
    border: 3px solid #000;
  }
}
.custom-vertical-scrollbar-hide {
  &::-webkit-scrollbar {
    height: 0px;
  }
}
.gemini-scrollbar {
  &::-webkit-scrollbar {
    width: 9px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d0d5dd;
    min-height: 48px;
    min-width: 48px;
  }
}

// FOR DEVELOPING : THIS KEEP TOOLTIP OPEN
// .apexcharts-tooltip {
// 	opacity: 1 !important;
// }

.custom-radio {
  input {
    &:checked {
      ~ {
        .checkmark {
          background-color: #3e3ce1;
          border: 1px solid $primary;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  .checkmark {
    border: 1px solid #d0d5dd;
    background-color: #fff;

    &:after {
      opacity: 0;
    }
  }
}
#brandingLogo {
  display: none;
}
.word-break {
  word-break: break-word;
}
.sales-datepicker {
  > div {
    &:first-child {
      @media (max-width: 1360px) {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }
}
.sales-datepicker-quote {
  > div {
    &:first-child {
      @media (max-width: 1360px) {
        padding-right: 4px;
      }
    }
  }
}
.single-select-x-scroll {
  .select__single-value {
    > div {
      display: block;
    }
    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
  }
}

.fl-input-field {
  @apply w-full flex items-center h-12;
}
.fl-input-field.fl-phone-field .fl-flag {
  @apply flex items-center px-[14px] grow-0 shrink-0 basis-[76px] h-12 rounded-3xl box-border cursor-pointer border border-transparent;
}
.fl-input-field.fl-phone-field .fl-flag {
  @apply active:border active:border-primary focus:border focus:border-primary outline-0 ring-0;
}
.fl-input-field.fl-phone-field .fl-flag .icon-angel {
  @apply ml-2 grow-0 shrink-0 basis-4;
}

.country-code-dropdown-list > li:hover,
.country-code-dropdown-list > li.active {
  color: $primary;
  background-color: rgba(22, 66, 197, 0.1);
}
.Toastify__toast-container {
  --toastify-z-index: 99999;
}
input::-webkit-inner-spin-button {
  display: none;
}
.tippy-tooltip.tippy-tooltip--regular {
  padding: 0;
  background-color: transparent;
}

/* Change the arrow color */

.tippy-popper[x-placement^='top'] .dark-theme [x-arrow] {
  bottom: -6px !important;
}
.tippy-popper[x-placement^='bottom'] [x-arrow] {
  top: -6px !important;
}
.tippy-popper[x-placement^='left'] [x-arrow] {
  right: -6px !important;
}
.tippy-popper[x-placement^='right'] [x-arrow] {
  left: -6px !important;
}
.tippy-popper[x-placement^='top'] .dark-theme [x-arrow] {
  border-top-color: #1f293c !important;
}

.tippy-popper[x-placement^='bottom'] .dark-theme [x-arrow] {
  border-bottom-color: #1f293c !important;
}

.tippy-popper[x-placement^='left'] .dark-theme [x-arrow] {
  border-left-color: #1f293c !important;
}

.tippy-popper[x-placement^='right'] .dark-theme [x-arrow] {
  border-right-color: #1f293c !important;
}

.tippy-popper {
  pointer-events: auto !important;
}
.disabled-input {
  &:disabled {
    + div {
      > svg {
        color: #667085;
      }
    }
  }
}
.currency-tab {
  > div {
    &:first-child {
      > div {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:nth-child(2) {
      > div {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  &.order-currency-tab {
    > div {
      @media (min-width: 640px) {
        width: 80px;
      }

      @media (min-width: 450px) {
        width: 80px;
      }
      @media (min-width: 768px) {
        width: 80px;
      }
      width: 50%;
    }
  }
  &.quoting-currency {
    > div {
      @media (min-width: 640px) {
        width: 80px;
      }

      @media (min-width: 450px) {
        width: 50px;
      }
      @media (min-width: 1024px) {
        width: 80px;
      }
      width: 50%;
    }
  }
}
// .pac-container.showAddressOnTop {
//   background-color: #079455;
// }

// .tooltipShow {
//     ~ {
//         .tippy-popper {
//             z-index: 99999 !important;
//         }
//     }
// }

@keyframes toast-open {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes toast-close {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.9);
  }
}
@keyframes toast-open-common {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes toast-close-common {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.Toastify__slide-enter {
  animation: toast-open 0.24s cubic-bezier(0.175, 0.885, 0.32, 1.175);
  opacity: 1;
}

.Toastify__slide-exit {
  animation: toast-close 0.12s cubic-bezier(0.4, 0, 1, 1);
  opacity: 0;
}
.Toastify__slide-enter-common {
  animation: toast-open-common 0.4s ease-in-out;
  opacity: 1;
}
.Toastify__slide-exit-common {
  animation: toast-close-common 0.4s ease-in-out;
  opacity: 0;
}
.Toastify__toast-container--top-center {
  left: 50% !important;
  transform: translateX(-50%) !important;
}
.Toastify__toast:not(.copy-msg) {
  .Toastify__close-button {
    padding-left: 10px;
  }
}
.trimblemaps-ctrl-bottom-left,
.trimblemaps-ctrl-bottom-right,
.trimblemaps-ctrl-top-left,
.trimblemaps-ctrl-top-right {
  z-index: 0;
}

.analytics_tooltip {
  .apexcharts-tooltip {
    background-color: white !important;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;

    &.apexcharts-theme-light,
    &.apexcharts-theme-Light {
      padding: 0 !important;
      border-radius: 8px !important;
      border: 1px solid #eaecf0 !important;
      > div {
        display: flex;
        background-color: white !important;
        gap: 4px;
        border: 0 !important;

        div {
          color: #101828 !important;
          font-weight: 500 !important;
        }
      }
    }
  }
}

.analytics_totalquotes_tooltip {
  .apexcharts-tooltip {
    padding: 0 10px;
    border-radius: 8px;
    border: 0 !important;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06) !important;

    .apexcharts-tooltip-title {
      padding: 6px 0px;
      background: white !important;
      font-weight: 500;
    }

    .apexcharts-tooltip-series-group {
      padding: 0px 0px 4px;

      .apexcharts-tooltip-marker {
        width: 8px;
        height: 8px;
        margin-right: 6px;
      }

      .apexcharts-tooltip-y-group {
        padding: 2px 0px;
      }
    }
  }

  .apexcharts-canvas {
    .apexcharts-graphical {
      .apexcharts-active {
        opacity: 1;
      }
    }
  }
}

.apexcharts-tooltip {
  &.apexcharts-theme-dark {
    background-color: #1f293c !important;
    padding: 0 5px;
    border-radius: 0.5rem;
    .apexcharts-tooltip-title {
      padding-top: 6px !important;
      border-bottom: 1px solid #98a2b3 !important;
      background-color: #1f293c !important;
    }
  }
}

.sales-chart-container {
  .apexcharts-tooltip {
    &.apexcharts-theme-dark {
      background-color: #fff !important;
      padding: 0 5px;
      border-radius: 0.8rem;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
      border: 1px solid#F2F4F7;
      .apexcharts-tooltip-title {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        border-bottom: 1px solid #eaecf0 !important;
        background-color: #fff !important;
      }
    }
  }
}
.tippy-popper {
  .tippy-tooltip.light-theme {
    background-color: transparent;
    box-shadow: none;
    margin-top: 6px;
  }
}
.record-per-table {
  > div {
    @media (min-width: 991px) {
      width: 73px;
    }
  }
}
.hover-text-wrap {
  &:hover {
    .hover-text {
      // width: 95px;

      span {
        opacity: 1;
        width: 73px;
        font-size: 12px;
        &.creditsafe-text {
          width: 85px;
        }
      }
    }
    .modal-hover-text {
      .hover-text {
        width: auto !important;
        span {
          @media (max-width: 767px) {
            width: 0 !important;
            opacity: 0 !important;
          }
        }
      }
    }
  }
}
// @property --num {
//   syntax: '<integer>';
//   initial-value: 0;
//   inherits: false;
// }

// .counter {
//   animation: counter 5s ease-in-out;
//   counter-reset: num var(--num);
//   font: 800 40px system-ui;
//   padding: 2rem;
// }
// .counter::after {
//   content: counter(num);
// }

// @keyframes counter {
//   from {
//     --num: 0;
//   }
//   to {
//     --num: 500;
//   }
// }

.datepicker-v2 {
  .react-datepicker__month-container {
    margin: 12px 0px;
    border: 0 !important;
    .react-datepicker-year-header {
      height: 32px;
      margin-bottom: 12px;
    }
    .react-datepicker__month {
      margin: 0;
    }
    .react-datepicker__month-text {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      color: #344054;
      width: 48px;
      margin: 6px;
      padding: 4px;
      border-radius: 9999px;
      &.react-datepicker__month-text--selected,
      &.react-datepicker__month-text--keyboard-selected {
        background-color: #444ce7;
        color: #fff;
      }
      @media (max-width: 1024px) {
        width: 45px;
      }
      @media (max-width: 767px) {
        width: 45px;
      }
    }
  }
  .react-datepicker__navigation {
    top: 12px;
    height: 32px;
    width: 32px;
    .react-datepicker__navigation-icon {
      &::before {
        top: 11.5px;
      }
    }
  }
  .react-datepicker__navigation--previous {
    margin: 0 0 0 10.5px;
  }
  .react-datepicker__navigation--next {
    margin: 0 10.5px 0 0;
  }
}
.progress-range-container {
  width: 100%;
  height: 10px;
  background-color: #eaecf0;
  border-radius: 8px;
  position: relative;
  .progress-range-bar {
    height: 100%;
    background-color: $primary;
    border-radius: 8px;
    position: relative;
    max-width: 100%;
    &.progress-success {
      background-color: #079455;
      .progress-range-thumb {
        border: 2px solid #079455;
      }
    }
    &.progress-yellow {
      background-color: #ffc450;
      .progress-range-thumb {
        border: 2px solid #ffc450;
      }
    }
    &.progress-danger {
      background-color: #d92d20;
      .progress-range-thumb {
        border: 2px solid #d92d20;
      }
    }
    &:hover {
      .progress-tooltip {
        opacity: 1;
      }
    }
    .progress-tooltip {
      opacity: 0;
    }
  }
  .progress-target {
    position: absolute;
    z-index: 1;
    top: 50%;
    .progress-target-sign {
      // height: 20px;
      // width: 2px;
      // display: block;
      // margin-top: -15px;
      // background: $primary;

      width: 16px;
      height: 16px;
      background-color: #fff;
      border: 2px solid #444ce7;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
    .progress-target-value {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      font-weight: 500;
      color: #98a2b3;
      margin-top: 2px;
    }
  }
  .progress-range-thumb {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 2px solid $primary;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    &.progress-range-thumb-invisible {
      width: 0;
      height: 0;
      border: 0;
    }
  }
  .tooltip-custom-arrow {
    &:after,
    &::before {
      border: solid transparent;
      content: ' ';
      display: block;
      height: 0;
      position: absolute;
      pointer-events: none;
      width: 0;
      top: 100%;
    }
    &::after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      left: 50%;
      margin-left: -7px;
      border-width: 6px;
    }
    &::before {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #eaecf0;
      left: 50%;
      margin-left: -9px;
      border-width: 8px;
    }
  }
  .tooltip-custom-top-arrow {
    &:after,
    &::before {
      border: solid transparent;
      content: ' ';
      display: block;
      height: 0;
      position: absolute;
      pointer-events: none;
      width: 0;
      bottom: 100%;
    }
    &::after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      left: 50%;
      margin-left: -7px;
      border-width: 6px;
    }
    &::before {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #eaecf0;
      left: 50%;
      margin-left: -9px;
      border-width: 8px;
    }
  }
}
.checkbox-fgSuccessPrimary {
  &:checked {
    background-image: linear-gradient(-45deg, transparent 65%, #079455 65.99%),
      linear-gradient(45deg, transparent 75%, #079455 75.99%),
      linear-gradient(-45deg, #079455 40%, transparent 40.99%),
      linear-gradient(
        45deg,
        #079455 30%,
        #fff 30.99%,
        #fff 40%,
        transparent 40.99%
      ),
      linear-gradient(-45deg, #fff 50%, #079455 50.99%);
  }
}
.typing-animation {
  div {
    animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
    background-color: #475467; //rgba(20,105,69,.7);
    border-radius: 50%;
    height: 4px;
    vertical-align: middle;
    width: 4px;
    display: inline-block;
    &:nth-child(1) {
      animation-delay: 200ms;
    }
    &:nth-child(2) {
      animation-delay: 400ms;
    }
    &:nth-child(3) {
      animation-delay: 600ms;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #475467; // rgba(20,105,69,.7);
  }
  28% {
    transform: translateY(-7px);
    background-color: #98a2b3; //rgba(20,105,69,.4);
  }
  44% {
    transform: translateY(0px);
    background-color: #475467; //rgba(20,105,69,.2);
  }
}

//scrollbar design
.main__layout_section::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.main__layout_section::-webkit-scrollbar-track {
  background: #f2f4f7;
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.main__layout_section::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #a0a4a8, #6d7278);
  border-radius: 10px;
  border: 2px solid #f2f4f7;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.main__layout_section::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #8a8e92, #6d7278);
}

.main__layout_section::-webkit-scrollbar-thumb:active {
  background: linear-gradient(180deg, #6d7278, #4b4f54);
}

//ck editor

.ck-editor {
  a {
    color: $primary !important;
  }
  ul,
  ol {
    padding: 20px;
  }
  .ck-editor__editable {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-color: #eaecf0 !important;
  }
  .ck-sticky-panel__content {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border-color: #eaecf0 !important;
  }
  .ck-toolbar {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }
}
.ck.ck-powered-by {
  display: none !important;
}

.totalweight-select {
  .select__control {
    border-left: 0 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-inner {
  display: none !important;
}

.imageCropper .cropper-view-box,
.imageCropper .cropper-face {
  border-radius: 50%;
}
/* The css styles for `outline` do not follow `border-radius` on iOS/Safari (#979). */
.imageCropper .cropper-view-box {
  outline: 0;
  box-shadow: 0 0 0 1px #39f;
}

.novel-editor-wrap {
  .tippy-box {
    max-width: unset !important;
  }
  iframe {
    max-width: 100%;
  }
  pre {
    background: #0d0d0d;
    border-radius: 0.5rem;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }

    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
}
[data-radix-popper-content-wrapper] {
  z-index: 999 !important;

  > div {
    background-color: #fff;
  }
}

[data-tippy-root] {
  max-width: 99%;
  overflow-y: auto;
}
.prose-lg {
  font-size: 14px;
}
.radial-progress {
  overflow: hidden;
  .target-div {
    position: absolute;
    inset: 0px;
    background: radial-gradient(farthest-side, currentColor 98%, #0000) top /
        var(--thickness) var(--thickness) no-repeat,
      conic-gradient(currentColor calc(var(--value) * 1%), #00000000 0);
    mask: radial-gradient(
      farthest-side,
      #0000 calc(99% - var(--thickness)),
      #000 calc(100% - var(--thickness))
    );

    opacity: 0.3;
  }
}
.radial-progress:before {
  background: radial-gradient(farthest-side, currentColor 98%, #0000) top / 3px
      3px no-repeat,
    conic-gradient(currentColor calc(var(--value) * 1%), #eaecf0 0);
}

.ck-content h1 {
  font-size: 2.5em;
}

.ck-content h2 {
  font-size: 2em;
}

.ck-content h3 {
  font-size: 1.75em;
}

.action-tooltip-custom {
  .arrow-regular {
    left: -14px !important;
  }
  &.action-tooltip-without-arrow {
    .arrow-regular {
      display: none;
    }
    .action-tooltip-select-wrap {
      margin-top: -10px;
      margin-bottom: -4px;
    }
  }
}

.track-trace-select {
  .wal-hq-select {
    .select__control {
      min-height: auto;
      border: 0;
      .select__value-container {
        padding-left: 8px;
        svg {
          color: #98a2b3;
        }
      }
      .select__indicator,
      .select__value-container {
        padding: 0;
      }
    }
  }
}

.ai-gradient-border-wrap {
  .select__control,
  input,
  .form_control {
    border-color: transparent !important;
    background: linear-gradient(to right, white, white),
      linear-gradient(to right, #9848e7 67%, #b944e7 100%) !important;
    background-clip: padding-box, border-box !important;
    background-origin: padding-box, border-box !important;
  }
  .select__control {
    .select__value-container {
      padding-left: 25px !important;
    }
    .select__value-container--is-multi {
      &.select__value-container.select__value-container--is-multi {
        padding-left: 25px !important;
      }
    }
  }
}
.ai-gradient-border {
  border-color: transparent !important;
  background: linear-gradient(to right, white, white),
    linear-gradient(to right, #9848e7 67%, #b944e7 100%) !important;
  background-clip: padding-box, border-box !important;
  background-origin: padding-box, border-box !important;
}

.checkmark-hide {
  .select__option {
    svg {
      display: none;
    }
  }
}

// REACT YEAR PICKER
.react-year-picker-main {
  .react-datepicker__year--container {
    min-width: 230px;
  }
  .react-datepicker__navigation {
    margin: 0 20.5px 0 0;
    top: 5px;
    height: 32px;
    width: 32px;
    &.react-datepicker__navigation--previous {
      margin: 0 0 0 20.5px;
    }
  }
  .react-datepicker__year-wrapper {
    max-width: unset;
  }
  .react-datepicker__year-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    color: #344054;
    margin: 4px;
    padding: 4px;
  }
  .react-datepicker__year-text--selected {
    background-color: #e0eaff;
  }
  .react-datepicker__header {
    margin: 12px 0 12px;
    color: #344054;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
  }
  .react-datepicker-popper {
    right: 0 !important;
    left: unset !important;
    margin-bottom: 6px;
  }
  .border-borderPrimary {
    height: 4px;
    background-color: #e0e0e0;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
  }
  .bg-primary {
    background-color: #007bff;
    height: 100%;
  }
}

//REACT TIME PICKER
.react-time-picker-main {
  .react-datepicker-popper {
    min-width: auto !important;
    padding: 8px;
  }

  .react-datepicker--time-only {
    .react-datepicker-time__header {
      display: none;
    }
    .react-datepicker__time-list {
      height: 100%;
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #eaecf0;
      }
      .react-datepicker__time-list-item {
        border-radius: 6px;
        font-weight: 500 !important;
        color: #101828;
        &:hover {
          background-color: #f9fafb !important;
        }
        &.react-datepicker__time-list-item--selected {
          background-color: #f9fafb !important;
          color: #444ce7 !important;
        }
      }
    }
  }
}

.target-month-picker {
  .react-datepicker__month-text {
    &.react-datepicker__month-text--selected,
    &.react-datepicker__month-text--keyboard-selected {
      background-color: #e0eaff !important;
      color: #101828 !important;
      font-weight: 500;
    }
  }
}
.icon-filled {
  &svg {
    path {
      &:first-child {
        stroke: #444ce7;
        fill: #444ce7;
        fill-opacity: 1;
      }
    }
  }
}
.icon-filled-current {
  path {
    stroke: currentColor;
    fill: currentColor;
    fill-opacity: 1;
  }
}

.shadow-blue-sm {
  box-shadow: 8px -8px 15.6px 0px rgba(211, 226, 255, 0.5);
}
.shadow-blue {
  box-shadow: 5px -5px 15px 0px rgba(211, 226, 255, 0.5);
}
.analytics-chart {
  .apexcharts-canvas {
    height: 100% !important;
  }

  .apexcharts-legend-series {
    display: flex;
    font-family: 'Inter', sans-serif !important;
    align-items: center;
    margin: 0 !important;
  }
  .apexcharts-legend {
    top: 50% !important;
    bottom: unset !important;
    transform: translateY(-50%);
    width: 240px;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
      0px 1px 2px 0px rgba(16, 24, 40, 0.06);
    padding: 16px;
    gap: 8px;
  }
  .apexcharts-legend-text {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif !important;
    color: #475467 !important;
    width: 100%;
    gap: 8px;
    p {
      flex: 1;
    }
    b {
      color: #101828 !important;
      font-weight: 600;
    }
  }
}
.mapboxgl-canvas {
  outline: 0;
}
.loader-with-bg-ring{
  position: relative;
  width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    .loading{
      z-index: 2;
    }
  &::after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: 2px solid #F2F4F7;
    border-radius: 50%;
    z-index: 0;
  }
}
.loading-spinner-custom{
  border-radius: 50%;
  width: 14px;
  height: 14px;
  border: 2px solid #F2F4F7;
  border-top-color: #444CE7;
  border-left-color: #444CE7;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// MULTISELECT DESIGN START
.select__menu-list--is-multi{
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  .select__option{
    width: calc(50% - 4px);
    font-size: 11px !important;
    .select-checkmark{
      display: none;
    }
  }
} 
.multi-select-v2{
  .wal-hq-select{
    .select__multi-value {
      padding: 0;
      background-color: transparent !important;
      display: inline;
    }
    .select__value-container{
      // display: block;
      // flex-wrap: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
    .select__multi-value__label{
      display: inline;
      padding: 3px 5px;
      border-radius:  6px;
      background:  #F2F4F7;
    }
    .multi-select-v2-value{
      display: inline;
      > div{
        display: inline;
      }
    }
  }
}

.transparent-disabled-select{
  .wal-hq-select {
    .select__control{

    }
    .select__control--is-disabled {
      background-color: transparent;
      .select__indicators{
        svg {
          color: #667085;
        }
      }
    }
     .select__control {
      .select__value-container{
        .select__single-value--is-disabled{
          color: #333333;
        }
      }
     }
    
  }
}